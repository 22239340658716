import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '@core/services';
import { AppGlobals } from '@app/app.global';

@Injectable()
export class SecondaryOwnerProhibitedGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private appGlobals: AppGlobals
    ) { }


    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.user?.isSecondaryOwner) {
            const currentURL = state.url;
            const isAccess = this.appGlobals.userAccessList.find(access => access.regex.test(currentURL));

            if (isAccess?.name && this.authService.user.userAccessList.includes(isAccess.name)) {
                return true;
            } else {

                const fistURLAccessName = this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.DASHBAORD) ? this.appGlobals.USER_ACCESS.DASHBAORD :
                    this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.INVOICE_LISTING) ? this.appGlobals.USER_ACCESS.INVOICE_LISTING :
                        this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.TAG_MANAGEMENT) ? this.appGlobals.USER_ACCESS.TAG_MANAGEMENT :
                            this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.PAYMENTS) ? this.appGlobals.USER_ACCESS.PAYMENTS :
                                this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.PUSH_TO_PETPOOJA) ? this.appGlobals.USER_ACCESS.PUSH_TO_PETPOOJA :
                                    this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.PATTY_EXPENSE) ? this.appGlobals.USER_ACCESS.PATTY_EXPENSE :
                                        this.authService.user.userAccessList.includes(this.appGlobals.USER_ACCESS.REPORTS) ? this.appGlobals.USER_ACCESS.REPORTS :
                                            this.authService.user.userAccessList.find(access => access !== this.appGlobals.USER_ACCESS.INVOICE_REVIEW);

                const firstURL = this.appGlobals.userAccessList.find(access => access.name === fistURLAccessName);

                this.router.navigate([firstURL?.url || '404']);
                return false;
            }
        } else {
            return true;
        }
    }
}