import { IUser, UserRole } from "@core/interfaces";
import { Company } from "./company.entity";
import { Entity } from "./entity";

export class User extends Entity<IUser> {
    get id() {
        return this.props.id;
    }

    get name() {
        return this.props.name;
    }

    get shortName() {
        return this.props.name.split(' ').reduce((combinedNamePart, namePart) => combinedNamePart + namePart[0], '')?.toUpperCase();
    }

    get mobile() {
        return this.props.mobile;
    }

    get email() {
        return this.props.email;
    }

    get role() {
        return this.props.role;
    }

    get company() {
        return this.props.company && new Company(this.props.company);
    }

    get isMobileVerified() {
        return this.props.isMobileVerified;
    }

    get isEmailVerified() {
        return this.props.isEmailVerified;
    }

    get isOnboarded() {
        return this.props.isOnboarded;
    }

    get status() {
        return this.props.status;
    }

    get isUploader(): boolean {
        return this.props.role === UserRole.Uploader;
    }

    get isReviewer(): boolean {
        return this.props.role === UserRole.Reviewer;
    }

    get isOutlet(): boolean {
        return this.props.role === UserRole.Outlet;
    }

    get isOwner(): boolean {
        return this.props.role === UserRole.Owner;
    }

    get isAdmin(): boolean {
        return this.props.role === UserRole.Admin;
    }

    get isLeadAdmin(): boolean {
        return this.props.role === UserRole.LeadAdmin;
    }

    get isSuperAdmin(): boolean {
        return this.props.role === UserRole.SuperAdmin;
    }

    get buyerSummary() {
        return this.props.buyerSummary;
    }

    get reviewerSummary() {
        return this.props.reviewerSummary;
    }

    get userAccessList() {
        return this.props.userAccessList;
    }

    get isSecondaryOwner() {
        return this.props.isSecondaryOwner;
    }

    get isDemo() {
        return this.props.isDemo;
    }
}