import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { AuthService, EntityService, PageMetaService, SharedService, User } from '@app/core';
import { NavbarService } from './navbar.service';
import { AppGlobals } from '@app/app.global';
import { debounceTime } from 'rxjs/operators';
import { InvoiceService } from '@app/modules/invoice/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: {
    class: 'px-0 container-fluid',
  }
})
export class NavbarComponent implements OnInit {
  loggedinUser: User;
  title$: Observable<string> = this.pageMetaService.title.asObservable();
  backLink$: Observable<string | any[]> = this.pageMetaService.backLink.asObservable();
  syncCode: string;
  isSetAllEntities = false;
  selectedEntities = new FormControl([]);
  selectedCompany = new FormControl();
  tempSelecteEntities: { id: string, name: string }[] = [];
  entities = [];
  hideBreadCrumb = true;
  headerFilterForm: FormGroup;
  parentCompanyList: any = [];
  selectedEntitiesList: any = [];
  entitiList: any = [];
  allEntities: any = [];
  selectedCompanySubscription: Subscription;
  showBckButton = true;
  selectedCompanyListSubscription: Subscription;
  invoiceActiveGroup = '';
  isSidebarOpen = true;
  dashboardText = false;

  constructor(
    private sharedService: SharedService,
    private pageMetaService: PageMetaService,
    public authService: AuthService,
    private navbarService: NavbarService,
    private invoiceService: InvoiceService,
    public router: Router,
    private formBuilder: FormBuilder,
    public appGlobals: AppGlobals,
    private entityService: EntityService,
    private cdRef: ChangeDetectorRef) {
    this.loggedinUser = this.authService.user;

    this.invoiceService.activeInvoiceGroup$.subscribe(async group => {
      if ((group && this.authService.user.isAdmin) && this.router.url === '/invoice') {

        this.invoiceActiveGroup = group;
        await this.getCompanyList();
      }
    });

    if (!this.authService.user.isAdmin) {
      this.getSyncCode();
    }

    this.router.events.subscribe((res: any) => {
      if (res && res.url?.includes('/dashboard')) {
        this.dashboardText = true;
      }
    })
  }

  async ngOnInit() {
    if (this.authService.user.isOwner) {
      this.setEntities();
    } else if (this.authService.user.isAdmin && this.router.url !== '/invoice') {
      await this.getCompanyList();
    }
  }

  createFilterForm() {
    this.selectedCompanySubscription = this.selectedCompany.valueChanges.pipe(debounceTime(1000)).subscribe(searchTerm => {
      this.selectedEntitiesList = this.allEntities.filter(company => company.parent === searchTerm);
      this.entities = [
        ...[{
          id: '0',
          name: 'All'
        }],
        {
          id: searchTerm,
          group: 'Parent Entity',
          name: this.parentCompanyList.find(p => p.id === searchTerm)?.name,
        },
        ...this.selectedEntitiesList?.map(entity => ({
          group: 'Child Entity',
          ...entity
        }))
      ];

      const lastSelectedCompany = this.entityService.getLastSelectedCompany();

      if (lastSelectedCompany != searchTerm) {
        this.selectedEntities.setValue(this.entities);
        this.entityService.setSelectedEntities(this.entities);
        this.entityService.setLastSelectedCompany(searchTerm);
        this.entityService.setEntities(this.entities);
        this.entityService.setEntitiesChanges({ isEntityChanged: true });
      } else {

        const selectedEntities = this.entityService.getSelectedEntities();
        if (selectedEntities.length) {
          this.selectedEntities.setValue(selectedEntities);
          this.entityService.setSelectedEntities(selectedEntities);
          this.entityService.setEntities(selectedEntities);
          this.entityService.setEntitiesChanges({ isEntityChanged: true });
        }
      }
    });
  }

  async getCompanyList() {
    this.selectedCompanyListSubscription = this.entityService.companies$.subscribe((response) => {
      if (!response.length) {
        return;
      }

      if (this.invoiceActiveGroup === 'assigned' && this.router.url === '/invoice') {
        this.parentCompanyList = response.filter(company => (company.parent === null || !company.parent) && company.assigned);
      } else {
        this.parentCompanyList = response.filter(company => company.parent === null || !company.parent);
      }

      this.allEntities = response.filter(company => company.parent !== null);
      const lastSelectedCompany = this.entityService.getLastSelectedCompany();
      this.createFilterForm();

      const companyNameExist = this.parentCompanyList.find(company => company.id === lastSelectedCompany);

      if (lastSelectedCompany && companyNameExist) {
        this.selectedCompany.setValue(lastSelectedCompany);
      } else {
        this.selectedCompany.setValue(this.parentCompanyList.length ? this.parentCompanyList[0].id : '');
      }
    });
  }

  setEntities() {
    this.entityService.entitiesSubject$.subscribe((entities) => {
      if (entities?.length) {

        // check if 'All' and 'parent' entity already exists then do not push
        if (!entities.some((entity) => entity.id === '0') && !entities.some((entity) => entity.id === this.authService.user.company.id)) {
          this.entities = [
            {
              id: '0',
              name: 'All'
            },
            {
              id: this.authService.user.company.id,
              group: 'Parent Entity',
              name: this.authService.user.company.name,
            },
            ...entities.map((outlet: any) => {
              return {
                id: outlet.id,
                name: outlet.name,
                group: 'Child Entity'
              }
            })
          ];
        } else {
          this.entities = entities;
        }

        // update entity name in case changed
        if (this.selectedEntities.value?.length) {
          const toBeUpdatedSelectedEntities: any[] = [...this.selectedEntities.value];
          toBeUpdatedSelectedEntities.forEach((entity) => {
            const findEntityIndex = entities.findIndex((findEntity) => findEntity.id === entity.id);
            if (findEntityIndex > -1) {
              entity.name = entities[findEntityIndex].name;
            }
          });
          this.selectedEntities.setValue(toBeUpdatedSelectedEntities);
          this.entityService.setSelectedEntities(toBeUpdatedSelectedEntities);
        }
      } else {
        this.entities = this.entityService.getEntities();

        if (!this.entities.length) {
          this.entityService.fetchEntities().subscribe((entities) => {
            this.entities = [
              {
                id: '0',
                name: 'All'
              },
              {
                id: this.authService.user.company.id,
                group: 'Parent Entity',
                name: this.authService.user.company.name,

              },
              ...(entities.map((entity) => {
                return {
                  id: entity.id,
                  name: entity.name,
                  group: 'Child Entity'
                }
              }))
            ];

            if (!this.selectedEntities.value.length) {
              this.tempSelecteEntities = [];
              this.entities.forEach((entity) => {
                this.tempSelecteEntities.push(entity);
              });
              this.selectedEntities.setValue(this.tempSelecteEntities);
              this.entityService.setEntities(this.entities);
              this.entityService.setSelectedEntities(this.selectedEntities.value);
            }
          })
        } else if (!this.entities.some((entity) => entity.id === '0') && !entities.some((entity) => entity.id === this.authService.user.company.id)) {
          // check if 'All' and 'parent' entity already exists then do not push
          this.entities = [
            {
              id: '0',
              name: 'All'
            },
            {
              id: this.authService.user.company.id,
              group: 'Parent Entity',
              name: this.authService.user.company.name,
            },
            ...this.entities.map((outlet: any) => {
              return {
                id: outlet.id,
                name: outlet.name,
                group: 'Child Entity'
              }
            })
          ];
          this.entityService.setEntities(this.entities);
        }
      }

    });
    this.entityService.selectedEntities$.subscribe((selectedEntities) => {

      if (selectedEntities.length) {
        if (selectedEntities.some((entity) => entity.id === '0') && this.entities?.some((entity) => entity.id === '0') && selectedEntities.length < this.entities.length) {
          selectedEntities = selectedEntities.filter((entity) => entity.id !== '0');
        }
        this.tempSelecteEntities = selectedEntities;
        this.selectedEntities.setValue(selectedEntities);
      } else {
        let selectedEntities: any = this.entityService.getSelectedEntities();

        if (selectedEntities.length) {
          this.tempSelecteEntities = selectedEntities;
          this.selectedEntities.setValue(this.tempSelecteEntities);
        }
      }
    });
  }

  onEntitySelectionClose() {
    this.entityService.setSelectedEntities(this.selectedEntities.value);
    if (!this.selectedEntities.value.length && this.entityService.getLastSelectedEntities().length) {
      this.tempSelecteEntities = this.entityService.getLastSelectedEntities();
      this.selectedEntities.setValue(this.tempSelecteEntities);
      this.entityService.setSelectedEntities(this.selectedEntities.value);
    }
    // check if entity dropdown value is changed then only call APIs
    if (JSON.stringify(this.selectedEntities.value) !== JSON.stringify(this.entityService.getLastSelectedEntities())) {
      this.entityService.setEntitiesChanges({ isEntityChanged: true });
    }
  }

  onEntityChange() {
    // check if selectedEntity has 'All' option then mark all other as selected
    if (this.selectedEntities.value?.findIndex((entity) => entity.id === '0') && this.selectedEntities.value.length && this.selectedEntities.value[this.selectedEntities.value.length - 1].id === '0') {
      this.selectedEntities.setValue(this.entities);
    } else if (this.selectedEntities.value?.findIndex((entity) => entity.id === '0') === -1) {
      if (this.tempSelecteEntities.findIndex((entity) => entity.id === '0') > -1) {
        this.selectedEntities.setValue([]);
      } else if (this.selectedEntities.value.length === this.entities.length - 1 && this.selectedEntities.value.findIndex((entity) => entity.id === '0') === -1) {
        const selectedEntities = this.selectedEntities.value;
        selectedEntities.unshift({ id: '0', name: 'All' });
        this.selectedEntities.setValue(selectedEntities);
      }
    }
    if (this.selectedEntities.value.findIndex((entity) => entity.id === '0') > -1 && this.selectedEntities.value.length == 1) {
      this.selectedEntities.setValue(this.entities);
    }
    if (this.selectedEntities.value.length && this.selectedEntities.value.length !== this.entities.length && this.selectedEntities.value[0].id === '0') {
      this.selectedEntities.setValue(this.selectedEntities.value.filter((entity) => entity.id !== '0'));
    }
    this.tempSelecteEntities = [...this.selectedEntities.value];
    this.entityService.setSelectedEntities(this.tempSelecteEntities);
  }

  compareWith(item, selected) {
    return item?.id === selected?.id;
  }

  /**
   * This method will set last selected entities in local storage
   * so if each entity is unchecked then, last selected entities will be 
   * selected by default on dropdown close
   */
  onSelectionOpen() {
    this.entityService.setLastSelectedEntities(this.selectedEntities.value);
  }

  showSideBar() {
    // this.isSidebarOpen = !this.isSidebarOpen;
    if (this.isSidebarOpen) {
      const sideBarElement = document.getElementById('leftSidebarMenu');

      if (sideBarElement) {
        document.body.classList.add('pmd-body-open');
        sideBarElement.classList.add('pmd-sidebar-open');
        this.cdRef.detectChanges();
      }
    }
    this.sharedService.sideBarToggle.emit(this.isSidebarOpen);
  }

  logout() {
    this.sharedService.cancelAPICalls$.next();
    this.invoiceService.invoiceFilters$?.unsubscribe();
    this.authService.logout();
  }

  /**
   * Retrieves active sync codes from the server. This method sends an HTTP GET request to the server to fetch active sync codes based on the specified parameters such as status, pageSize, and page.
   * @author Darshan Malani - 6th Sep 2023, 02:00 PM
   * @param
   */
  getSyncCode() {
    this.navbarService.getSyncCode({
      status: 'Active',
      pageSize: '1',
      page: '1',
      sortOrder: 'ASC',
      sortBy: 'createdAt'
    }).subscribe((syncCodes) => {
      if (syncCodes?.length) {
        this.syncCode = syncCodes[0];
      } else {
        this.createSyncCode();
      }
    }, (error: any) => {
      // this.sharedService.errorAlert(error)
    });
  }

  /**
   * This function makes an API request to generate a new sync code, and upon success,
   * it updates the current sync code in the UI. If an error occurs during the process,
   * it displays an error alert to notify the user.
   * @author Darshan Malani - 9th Sep 2023, 07:22:00 PM
   * @returns {void}
   */
  createSyncCode() {
    this.navbarService.createSyncCode({ count: 1 }).subscribe(
      (syncCodes) => {
        if (syncCodes?.length) {
          this.syncCode = syncCodes[0];
        } else {
          this.syncCode = '';
        }
      },
      (error: any) => this.sharedService.errorAlert(error)
    );
  }

  /**
   * Copies the sync code to the clipboard and displays a success alert.
   * This function creates a temporary textarea element, sets its value to the sync code,
   * copies the content to the clipboard using the 'execCommand' method, and then removes
   * the temporary textarea. It also displays a success alert to notify the user of the
   * successful copy operation.
   * @author Darshan Malani - 9th Sep 2023, 02:00 PM
   * @returns {void}
   */
  copySyncCodeToClipboard() {
    this.sharedService.copyToClipboard(this.syncCode);
    this.sharedService.successAlert(`Sync Code copied successfully.`);
  }

  /**
   * Refreshes the sync code by deactivating the current code and generating a new one if require.
   * This function deactivates the current sync code by making an API request to set its
   * status to 'Inactive'. After successful deactivation, it triggers the generation of
   * a new sync code and updates the UI. It also handles errors by refreshing the sync code
   * in case of failure.
   * @author Darshan Malani - 9th Sep 2023, 04:00 PM
   * @param {Event} event - The event object to stop propagation.
   * @returns {void}
   */
  refreshSyncCode(event: Event) {
    event.stopPropagation();

    if (this.loggedinUser.isSecondaryOwner) {
      return;
    }

    this.navbarService.deactivateSyncCode({
      ids: [this.syncCode],
      status: 'Inactive'
    }).subscribe((syncCodes) => {
      this.getSyncCode();
    }, (error: any) => this.getSyncCode());
  }

  ngOnDestroy(): void {
    this.selectedCompanySubscription?.unsubscribe();
    this.selectedCompanyListSubscription?.unsubscribe();
  };
}