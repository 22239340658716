import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ToastrModule } from 'ngx-toastr';
import { QuicklinkModule } from 'ngx-quicklink';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppGlobals } from './app.global';
import { SharedModule } from './shared/shared.module';
import {
  AuthService,
  AuthGuard,
  NoAuthGuard,
  appInitializer,
  ErrorInterceptor,
  TokenInterceptor,
  SharedService,
  CaseInterceptor,
  CaseService,
  HeaderInterceptor,
  AdminGuard,
  OwnerGuard,
  OwnerEntityGuard,
  LeadAdminGuard,
  LeadAdminProhibitedGuard,
  EncodeHttpParamsInterceptor,
  AdminProhibitedGuard,
  SecondaryOwnerProhibitedGuard
  //RetryInterceptor
} from '@app/core';
import {
  BlankLayoutComponent,
  LoginLayoutComponent,
  HomeLayoutComponent,
  EmptyLayoutComponent
} from '@app/layout';

@NgModule({
  declarations: [
    AppComponent,
    BlankLayoutComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    EmptyLayoutComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    QuicklinkModule,
    ScrollingModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/"
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
      deps: [AuthService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [AuthService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaseInterceptor,
      multi: true,
      deps: [CaseService]
    },
    AppGlobals,
    AuthGuard,
    NoAuthGuard,
    AdminGuard,
    OwnerGuard,
    OwnerEntityGuard,
    LeadAdminProhibitedGuard,
    SecondaryOwnerProhibitedGuard,
    AdminProhibitedGuard,
    LeadAdminGuard,
    SharedService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: RetryInterceptor,
    //   multi: true
    // } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }