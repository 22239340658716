import { ICompany } from "./company.interface";

export enum UserRole {
    SuperAdmin = 'Super Admin',
    LeadAdmin = 'Lead Admin',
    Admin = 'Admin',
    Owner = 'Owner',
    Outlet = 'Outlet',
    Uploader = 'Uploader',
    Reviewer = 'Reviewer'
}

export interface IUser {
    id: string;
    name: string;
    email: string;
    mobile: string;
    role: UserRole;
    company: ICompany;
    password?: string;
    isEmailVerified: boolean;
    isMobileVerified: boolean;
    isOnboarded: boolean,
    status: boolean;
    createdAt?: string;
    updatedAt?: string;
    buyerSummary?: boolean;
    reviewerSummary?: boolean;
    userAccessList?: [String];
    isSecondaryOwner?: boolean;
    isDemo?: boolean;
}